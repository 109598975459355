import { getToken } from '../helpers'
import { API } from '../constant'

export const getUserWithPlanAndDomains = async () => {
  try {
    const token = getToken()

    // Sử dụng populate nhiều cấp độ để lấy cả thông tin nested trong plan và domains
    const response = await fetch(
      `${API}/users/me?populate[plan][populate]=plan,amount,expiration_date&populate=domains`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    const data = await response.json()

    return data
  } catch (error) {
    console.error('Error fetching user plan and domains:', error)
    throw error
  }
}
