import React from 'react'

import DomainList from '../../components/Domain/Table'
const Test = () => {
  return (
    <div>
      <DomainList />
    </div>
  )
}

export default Test
