import React from 'react'
import { Card, Typography } from 'antd' // Import các component của Ant Design
import ChangePassword from '../components/Profile/ChangePassword' // Import component đổi mật khẩu

const { Title } = Typography // Destructure Typography để lấy Title

const ChangePasswordPage = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
      }}
    >
      <Card
        style={{ width: 400 }}
        title={<Title level={3}>Đổi mật khẩu</Title>} // Sử dụng Typography.Title để thay thế h1
        bordered={false}
      >
        <ChangePassword /> {/* Thêm component đổi mật khẩu */}
      </Card>
    </div>
  )
}

export default ChangePasswordPage
