import React, { useState, useEffect } from 'react'
import { Spin, Typography, Card } from 'antd'
import axios from 'axios'
import { API } from '../constant'

const { Title } = Typography

const ContactPage = () => {
  const [contactData, setContactData] = useState(null)
  const [loading, setLoading] = useState(true)

  // Hàm lấy dữ liệu từ Strapi
  const fetchContactData = async () => {
    try {
      const response = await axios.get(`${API}/contact`)
      if (response.data && response.data.data) {
        setContactData(response.data.data.attributes)
      }
    } catch (error) {
      console.error('Error fetching contact data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchContactData()
  }, [])

  if (loading) {
    return <Spin size="large" />
  }

  return (
    <Card
      style={{
        maxWidth: '1200px',
        width: '100%',
        margin: '0 auto',
        padding: '20px'
      }}
    >
      {contactData ? (
        <>
          {/* <Title level={2}>{contactData.title}</Title> */}
          {/* Sử dụng dangerouslySetInnerHTML để render HTML */}
          <div dangerouslySetInnerHTML={{ __html: contactData.content }} />
        </>
      ) : (
        <p>No contact data found.</p>
      )}
    </Card>
  )
}

export default ContactPage
