import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Spin, Card, Typography, message, Image, Row, Col } from 'antd'
import { getInvoiceByCode } from '../api/invoiceApi' // API để lấy thông tin invoice
const { Text, Title } = Typography

const PayMoney = () => {
  const [searchParams] = useSearchParams() // Lấy tham số từ URL
  const [invoice, setInvoice] = useState(null) // Lưu thông tin invoice
  const [loading, setLoading] = useState(true) // Trạng thái loading
  const [timeLeft, setTimeLeft] = useState(600) // 10 phút (600 giây) đếm ngược
  const [hasPaid, setHasPaid] = useState(false) // Trạng thái kiểm tra đã thanh toán
  const code = searchParams.get('code') // Lấy mã code từ URL

  // Hàm gọi API để lấy thông tin hoá đơn
  const fetchInvoice = async () => {
    try {
      const data = await getInvoiceByCode(code)

      // Kiểm tra nếu không có invoice nào được trả về
      if (data.data.length === 0) {
        message.error('Không tìm thấy hoá đơn')
        return
      }

      setInvoice(data.data[0]) // Lưu invoice vào state
    } catch (error) {
      message.error('Không thể tải hoá đơn')
    } finally {
      setLoading(false)
    }
  }

  // Gọi API để lấy dữ liệu khi trang được render
  useEffect(() => {
    if (code) {
      fetchInvoice()
    }
  }, [code])

  // Kiểm tra trạng thái thanh toán mỗi 10 giây nếu hoá đơn ở trạng thái "pending"
  useEffect(() => {
    if (invoice && invoice.attributes.status === 'pending') {
      const interval = setInterval(() => {
        fetchInvoice() // Tải lại trạng thái hoá đơn
      }, 10000) // Mỗi 10 giây

      // Xoá interval khi unmount hoặc khi trạng thái không còn là "pending"
      return () => clearInterval(interval)
    }
  }, [invoice])

  // Sử dụng useEffect để kiểm tra khi trạng thái thay đổi sang "done" và chỉ hiển thị thông báo một lần
  useEffect(() => {
    if (invoice && invoice.attributes.status === 'done' && !hasPaid) {
      message.success('Thanh toán thành công! Cảm ơn bạn đã sử dụng dịch vụ.')
      setHasPaid(true) // Đánh dấu rằng đã thanh toán để không hiển thị lại thông báo
    }
  }, [invoice, hasPaid])

  // Xử lý đếm ngược
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [timeLeft])

  if (loading) {
    return <Spin size="large" />
  }

  if (!invoice) {
    return <p>Không có hoá đơn nào</p>
  }

  // Lấy thông tin từ invoice
  const { amount, status, purchaseDate, plan, users_permissions_user } =
    invoice.attributes

  // Thông tin QR code
  const bank = 'acb'
  const numberAccount = '8903157'
  const name = 'Võ Thanh Lâm'
  const typeBank = 'compact2'
  const qrCodeSrc = `https://img.vietqr.io/image/${bank}-${numberAccount}-${typeBank}.jpg?amount=${amount}&addInfo=${invoice.attributes.code}&accountName=${name}`

  // Format số tiền với dấu chấm phân cách phần nghìn
  const formattedAmount = amount.toLocaleString('vi-VN')

  // Hiển thị đếm ngược (phút:giây)
  const minutes = Math.floor(timeLeft / 60)
  const seconds = timeLeft % 60

  // Điều chỉnh bố cục dựa trên trạng thái của hóa đơn
  const layoutSpan = status === 'pending' ? 12 : 24

  return (
    <Card
      style={{
        maxWidth: status === 'pending' ? '800px' : '600px',
        margin: '0 auto',
        padding: '20px'
      }}
    >
      <Title level={3}>Thông tin thanh toán</Title>
      <Row gutter={16}>
        {/* Cột bên trái hoặc duy nhất: Thông tin hoá đơn, thông báo chuyển khoản và đồng hồ đếm ngược */}
        <Col span={layoutSpan}>
          <Text strong>Mã hoá đơn: </Text>
          <Text>{invoice.attributes.code}</Text>
          <br />
          <Text strong>Số tiền: </Text>
          <Text>{formattedAmount} VND</Text>
          <br />
          <Text strong>Ngày tạo: </Text>
          <Text>{new Date(purchaseDate).toLocaleDateString()}</Text>
          <br />
          <Text strong>Trạng thái: </Text>
          <Text>
            {status === 'pending' ? 'Chờ thanh toán' : 'Đã thanh toán'}
          </Text>
          <br />
          <Text strong>Gói đã chọn: </Text>
          <Text>{plan?.data?.attributes?.name || 'Không có'}</Text>
          <br />
          <Text strong>Tài khoản người dùng: </Text>
          <Text>
            {users_permissions_user?.data?.attributes?.username || 'Không có'}
          </Text>
          <br />
          <br />
          {/* Thông báo chuyển khoản */}
          {status === 'pending' && (
            <>
              <Text style={{ color: 'red', fontWeight: 'bold' }}>
                Vui lòng chuyển khoản số tiền {formattedAmount} VND đến tài
                khoản ngân hàng ACB - {numberAccount}, với nội dung: "
                {invoice.attributes.code}"
              </Text>
              <br />
              <Text strong>Hoặc quét mã QR để thanh toán:</Text>
              <br />
              {/* Đồng hồ đếm ngược */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  marginTop: '20px'
                }}
              >
                <Text strong style={{ fontSize: '18px' }}>
                  Thời gian còn lại để thanh toán:
                </Text>
                <div
                  style={{
                    fontSize: '32px',
                    fontWeight: 'bold',
                    backgroundColor: '#f0f0f0',
                    padding: '10px 20px',
                    borderRadius: '8px',
                    marginTop: '10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                  }}
                >
                  {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </div>
              </div>
            </>
          )}
        </Col>

        {/* Cột bên phải chỉ hiển thị khi trạng thái là "pending" */}
        {status === 'pending' && (
          <Col span={12} style={{ textAlign: 'center' }}>
            <Image
              src={qrCodeSrc}
              alt="QR code thanh toán"
              width={300}
              height={300}
              style={{ display: 'block', margin: '20px auto' }}
            />
          </Col>
        )}
      </Row>
    </Card>
  )
}

export default PayMoney
