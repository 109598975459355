import React from 'react'
import { Layout } from 'antd'
const { Footer } = Layout

export default function Headers() {
  return (
    <Footer
      style={{
        textAlign: 'center'
      }}
    >
      Creta Technology ©{new Date().getFullYear()} Created Tal Tal
    </Footer>
  )
}
