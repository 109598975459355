import React, { useState } from 'react'
import { Form, Input, Button, message, Spin, Typography } from 'antd'
import { getToken } from '../../helpers'
import { API } from '../../constant'

const { Title } = Typography

const ChangePassword = () => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false) // Trạng thái hiển thị form hoặc thông báo

  const handleChangePassword = async values => {
    const { currentPassword, newPassword, confirmPassword } = values

    if (newPassword !== confirmPassword) {
      message.error('Mật khẩu mới và xác nhận mật khẩu không khớp!')
      return
    }

    setLoading(true)
    try {
      const token = getToken()
      const response = await fetch(`${API}/auth/change-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          password: newPassword,
          currentPassword: currentPassword,
          passwordConfirmation: newPassword
        })
      })

      const data = await response.json()

      if (response.ok) {
        message.success('Đổi mật khẩu thành công!')
        setSuccess(true) // Đánh dấu thành công và ẩn form
      } else {
        message.error(data.error.message || 'Đổi mật khẩu thất bại')
      }
    } catch (error) {
      console.error('Lỗi khi đổi mật khẩu:', error)
      message.error('Có lỗi xảy ra khi đổi mật khẩu.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div style={{ maxWidth: '400px', margin: 'auto' }}>
      <Title level={2}></Title>

      {success ? (
        <p>Mật khẩu của bạn đã được đổi thành công.</p>
      ) : (
        <Form layout="vertical" onFinish={handleChangePassword}>
          <Form.Item
            label="Mật khẩu hiện tại"
            name="currentPassword"
            rules={[
              { required: true, message: 'Vui lòng nhập mật khẩu hiện tại!' }
            ]}
          >
            <Input.Password placeholder="Nhập mật khẩu hiện tại" />
          </Form.Item>

          <Form.Item
            label="Mật khẩu mới"
            name="newPassword"
            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu mới!' }]}
          >
            <Input.Password placeholder="Nhập mật khẩu mới" />
          </Form.Item>

          <Form.Item
            label="Xác nhận mật khẩu mới"
            name="confirmPassword"
            rules={[
              { required: true, message: 'Vui lòng xác nhận mật khẩu mới!' }
            ]}
          >
            <Input.Password placeholder="Xác nhận mật khẩu mới" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              {loading ? <Spin size="small" /> : 'Đổi mật khẩu'}
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  )
}

export default ChangePassword
