import React, { useState, useEffect } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { message } from 'antd'
import { API, BEARER } from '../../constant'
import { getToken, removeToken } from '../../helpers'
import { useNavigate, useLocation } from 'react-router-dom'

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation() // Sử dụng useLocation để lấy đường dẫn hiện tại

  const authToken = getToken(navigate) // Truyền navigate vào getToken
  // console.log('authToken', authToken)
  const fetchLoggedInUser = async token => {
    setIsLoading(true)
    try {
      const response = await fetch(`${API}/users/me`, {
        headers: { Authorization: `${BEARER} ${token}` }
      })
      if (response.status === 401) {
        // Token không hợp lệ hoặc hết hạn, xoá token và điều hướng đến trang đăng nhập
        removeToken() // Hoặc localStorage.removeItem('authToken');
        // navigate('/login') // Điều hướng về trang đăng nhập
        return
      }
      const data = await response.json()
      // console.log('data', data)
      setUserData(data)
    } catch (error) {
      console.error(error)
      message.error('Error While Getting Logged In User Details')
    } finally {
      setIsLoading(false)
    }
  }

  const handleUser = user => {
    setUserData(user)
  }

  useEffect(() => {
    const publicPaths = ['/signin', '/signup', '/reset-password']
    // Lấy path chính mà không quan tâm đến query parameters
    const currentPath = location.pathname.split('?')[0]
    if (!authToken) {
      if (!publicPaths.includes(currentPath)) {
        // Nếu không phải là đường dẫn public, điều hướng về trang đăng ký
        navigate('/signup', { replace: true })
      } else {
        // Nếu đường dẫn nằm trong danh sách publicPaths, giữ nguyên
        // navigate(location.pathname, { replace: true })
      }
    } else {
      fetchLoggedInUser(authToken)
    }
  }, [authToken, location.pathname, navigate]) // Lắng nghe thay đổi của authToken và location.pathname

  return (
    <AuthContext.Provider
      value={{ user: userData, setUser: handleUser, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
