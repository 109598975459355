import React from 'react'
import { Layout, Menu, Dropdown, Avatar, Space } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { removeToken } from '../../helpers' // Import hàm removeToken

const { Header } = Layout

const menuItems = [
  { key: 'home', label: 'Trang chủ', path: '/' },
  { key: 'profile', label: 'Cá nhân', path: '/profile' },
  { key: 'contact', label: 'Liên lạc', path: '/contact' }
]

export default function Headers() {
  const navigate = useNavigate()

  const handleClick = e => {
    const selectedItem = menuItems.find(item => item.key === e.key)
    if (selectedItem) {
      navigate(selectedItem.path)
    }
  }

  const handleMenuClick = e => {
    switch (e.key) {
      case 'profile':
        navigate('/profile')
        break

      case 'logout':
        // Thêm logic logout tại đây
        removeToken() // Gọi hàm xóa token
        navigate('/signin') // Điều hướng đến trang đăng nhập
        break

      case 'change-password':
        navigate('/change-password') // Điều hướng đến trang đổi mật khẩu
        break

      default:
        break
    }
  }

  const userMenu = (
    <Menu
      items={[
        { key: 'profile', label: 'Cá nhân' },
        { key: 'change-password', label: 'Đổi mật khẩu' },
        { key: 'logout', label: 'Đăng xuất' }
      ]}
      onClick={handleMenuClick}
    />
  )

  return (
    <Header
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['customer']}
          items={menuItems}
          onClick={handleClick}
          style={{
            flex: 1,
            minWidth: 0
          }}
        />
      </div>
      <Space style={{ marginRight: 16 }}>
        <Dropdown overlay={userMenu} trigger={['click']}>
          <Avatar icon={<UserOutlined />} style={{ cursor: 'pointer' }} />
        </Dropdown>
      </Space>
    </Header>
  )
}
