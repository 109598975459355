import { Button, Card, Col, Form, Input, message, Row } from 'antd'
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { API } from '../constant'

const ResetPassword = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [code, setCode] = useState('')

  // Lấy code từ query string
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const resetCode = queryParams.get('code')
    if (resetCode) {
      setCode(resetCode)
    } else {
      message.error('Không tìm thấy mã reset password!')
      navigate('/signin')
    }
  }, [location, navigate])

  const onFinish = async values => {
    if (!code) return

    setIsLoading(true)
    try {
      const response = await fetch(`${API}/auth/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          code, // Mã code lấy từ query string
          password: values.password,
          passwordConfirmation: values.passwordConfirmation
        })
      })

      const data = await response.json()
      if (data?.error) {
        throw data?.error
      } else {
        message.success('Mật khẩu đã được đặt lại thành công!')
        navigate('/signin')
      }
    } catch (error) {
      console.error(error)
      setError(error?.message ?? 'Có lỗi xảy ra!')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Row align="middle" style={{ minHeight: '100vh' }}>
      <Col span={8} offset={8}>
        <Card title="Đặt lại mật khẩu">
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <Form
            name="reset_password"
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Mật khẩu mới"
              name="password"
              rules={[
                { required: true, message: 'Vui lòng nhập mật khẩu mới!' }
              ]}
            >
              <Input.Password placeholder="Mật khẩu mới" />
            </Form.Item>

            <Form.Item
              label="Xác nhận mật khẩu"
              name="passwordConfirmation"
              rules={[
                { required: true, message: 'Vui lòng xác nhận mật khẩu!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error('Mật khẩu xác nhận không khớp!')
                    )
                  }
                })
              ]}
            >
              <Input.Password placeholder="Xác nhận mật khẩu" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Đặt lại mật khẩu
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  )
}

export default ResetPassword
