import React, { useState, useEffect } from 'react'
import { List, Radio, Button, Spin, message, Typography, Card } from 'antd'
import { getAllPlans } from '../../api/planApi' // API để lấy tất cả các gói Plan
import { createInvoice } from '../../api/invoiceApi' // API để tạo invoice
import { useNavigate } from 'react-router-dom' // Để điều hướng người dùng
import { useAuthContext } from '../../context/AuthContext' // Sử dụng AuthContext để lấy thông tin người dùng

const { Text, Title } = Typography

const ChangePlan = ({ onPlanChange }) => {
  const [plans, setPlans] = useState([]) // Lưu danh sách Plan
  const [loading, setLoading] = useState(true) // Trạng thái loading
  const [selectedPlan, setSelectedPlan] = useState(null) // Lưu plan được chọn
  const [isProcessing, setIsProcessing] = useState(false) // Trạng thái xử lý
  const navigate = useNavigate() // Hook điều hướng
  const { user, isLoading } = useAuthContext() // Lấy thông tin người dùng từ AuthContext

  // Hàm tải tất cả các gói Plan từ API
  const fetchPlans = async () => {
    try {
      const data = await getAllPlans() // Gọi API để lấy tất cả các Plan
      setPlans(data.data) // Lưu kế hoạch vào state
    } catch (error) {
      message.error('Không thể tải danh sách gói!')
    } finally {
      setLoading(false)
    }
  }

  // Sử dụng useEffect để tải dữ liệu khi component được render
  useEffect(() => {
    fetchPlans()
  }, [])

  // Hàm xử lý khi chọn gói Plan
  const handlePlanSelect = e => {
    const selected = plans.find(plan => plan.id === e.target.value) // Tìm gói được chọn từ danh sách
    setSelectedPlan(selected) // Lưu gói Plan đã chọn vào state
  }

  // Hàm delay (chờ đợi)
  const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

  // Hàm xử lý khi bấm nút "Xác nhận"
  const handleConfirm = async () => {
    if (!selectedPlan) {
      message.error('Vui lòng chọn một gói!')
      return
    }

    if (!user) {
      message.error(
        'Không tìm thấy thông tin người dùng. Vui lòng đăng nhập lại.'
      )
      return
    }

    try {
      setIsProcessing(true) // Bắt đầu quá trình xử lý

      // Tạo dữ liệu invoice
      const invoiceData = {
        data: {
          code: `INV-${Date.now()}`, // Mã invoice là duy nhất, có thể là số timestamp
          amount: selectedPlan.attributes.price, // Số tiền dựa trên giá của plan
          users_permissions_user: { id: user.id }, // ID người dùng từ AuthContext
          plan: { id: selectedPlan.id }, // Gói plan mà người dùng chọn
          purchaseDate: new Date().toISOString(), // Ngày tạo invoice
          status: 'pending' // Trạng thái thanh toán (có thể là pending)
        }
      }

      // Gọi API để tạo invoice
      const createdInvoice = await createInvoice(invoiceData)

      // Hiển thị thông báo thành công
      message.success('Tạo hóa đơn thành công! Đang xử lý...', 3)

      // Hiển thị trạng thái xử lý trong 3 giây
      await delay(3000)

      // Hiển thị thông báo chuyển hướng
      message.info('Đang chuyển hướng đến trang thanh toán...', 3)

      // Chờ thêm 3 giây rồi điều hướng người dùng sang trang /paymoney với mã invoice
      await delay(3000)
      navigate(`/paymoney?code=${createdInvoice.data.attributes.code}`)
    } catch (error) {
      message.error('Không thể tạo hóa đơn!')
      console.error(error)
    } finally {
      setIsProcessing(false) // Kết thúc quá trình xử lý
    }
  }

  if (loading || isProcessing || isLoading) {
    return <Spin size="large" />
  }

  return (
    <div>
      <List
        dataSource={plans}
        renderItem={plan => (
          <List.Item>
            <Card
              style={{
                border:
                  selectedPlan?.id === plan.id
                    ? '2px solid #1890ff'
                    : '1px solid #f0f0f0',
                borderRadius: '8px',
                marginBottom: '16px',
                padding: '2px',
                width: '100%',
                cursor: 'pointer'
              }}
              onClick={() => handlePlanSelect({ target: { value: plan.id } })} // Cho phép chọn gói bằng cách click vào card
            >
              <Radio.Group value={selectedPlan?.id}>
                <Radio value={plan.id} style={{ display: 'none' }} />
              </Radio.Group>
              <Title level={4} style={{ marginBottom: '6px' }}>
                {plan.attributes.name}
              </Title>
              <Text strong>Số lượng domain: </Text>
              <Text>{plan.attributes.amount}</Text>
              <br />
              <Text strong>Số ngày cộng thêm: </Text>
              <Text>{plan.attributes.expiration_date || 'Không có'}</Text>
              <br />
              <Text strong>Mô tả: </Text>
              <Text>{plan.attributes.description || 'Không có mô tả'}</Text>
            </Card>
          </List.Item>
        )}
      />

      <Button type="primary" onClick={handleConfirm} disabled={!selectedPlan}>
        Xác nhận
      </Button>
    </div>
  )
}

export default ChangePlan
