import {
  getDomains,
  deleteDomain,
  syncDomain,
  updateDomainIP
} from '../../api/domainApi'
import { useState, useEffect } from 'react'
import { Table, Spin, Button, Input, Row, Col, Popconfirm, message } from 'antd'
import {
  DeleteOutlined,
  SyncOutlined,
  SaveOutlined,
  EditOutlined
} from '@ant-design/icons'
import moment from 'moment'
import CreateDomain from './CreateDomain'

const DomainList = () => {
  const [domains, setDomains] = useState([])
  const [filteredDomains, setFilteredDomains] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [editingIP, setEditingIP] = useState({}) // Để lưu trữ IP đang được chỉnh sửa
  const [editingRow, setEditingRow] = useState(null) // Lưu trạng thái domain nào đang được chỉnh sửa

  const fetchDomains = async () => {
    try {
      const data = await getDomains()
      setDomains(data)
      setFilteredDomains(data) // Gán ban đầu cho danh sách đã lọc
      setLoading(false)
    } catch (error) {
      console.error('Error fetching domains:', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchDomains()
  }, [])

  const handleSearch = e => {
    const value = e.target.value.toLowerCase()
    setSearchText(value)

    const filteredData = domains.filter(domain =>
      domain.subdomain.toLowerCase().includes(value)
    )
    setFilteredDomains(filteredData)
  }

  const handleDomainCreated = () => {
    fetchDomains()
    setIsModalVisible(false) // Đóng modal sau khi tạo xong
  }

  const handleDelete = async domainId => {
    try {
      await deleteDomain(domainId)
      message.success('Domain deleted successfully')
      fetchDomains() // Cập nhật danh sách domain sau khi xóa thành công
    } catch (error) {
      message.error('Failed to delete domain')
    }
  }

  const handleSync = async (domainId, userId) => {
    try {
      await syncDomain(domainId, userId) // Gọi API để đồng bộ domain
      message.success('Domain synced successfully')

      // Gọi lại API để lấy dữ liệu domain mới nhất và cập nhật danh sách
      fetchDomains()
    } catch (error) {
      message.error('Failed to sync domain')
    }
  }

  const handleEditIP = (domainId, newIP) => {
    // Cập nhật giá trị IP trong trạng thái editingIP
    setEditingIP(prevState => ({
      ...prevState,
      [domainId]: newIP
    }))
  }

  const handleSaveIP = async domainId => {
    const newIP = editingIP[domainId] // Lấy IP mới từ trạng thái

    try {
      // Gọi API để cập nhật IP
      await updateDomainIP(domainId, newIP)
      message.success('IP updated successfully')

      // Tải lại danh sách domain sau khi cập nhật
      fetchDomains()

      // Reset lại trạng thái chỉnh sửa
      setEditingRow(null)
    } catch (error) {
      message.error('Failed to update IP')
    }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Subdomain',
      dataIndex: 'subdomain',
      key: 'subdomain'
    },
    {
      title: 'Hostname',
      dataIndex: 'hostname', // Dữ liệu lấy từ trường hostname
      key: 'hostname'
    },
    {
      title: 'IP Address',
      key: 'ip',
      render: (text, record) => (
        <>
          {editingRow === record.id ? (
            <>
              {/* Input để chỉnh sửa IP */}
              <Input
                value={editingIP[record.id] || record.ip} // Hiển thị IP hiện tại hoặc IP đang chỉnh sửa
                onChange={e => handleEditIP(record.id, e.target.value)} // Cập nhật IP khi người dùng thay đổi
                style={{ width: '200px', marginRight: '8px' }}
              />
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() => handleSaveIP(record.id)} // Lưu IP mới
              >
                Lưu
              </Button>
            </>
          ) : (
            <>
              {/* Hiển thị IP */}
              <span>{record.ip}</span>
              <Button
                type="link"
                icon={<EditOutlined />} // Icon cây bút
                onClick={() => setEditingRow(record.id)} // Chuyển sang chế độ chỉnh sửa khi bấm vào cây bút
                style={{ marginLeft: '8px' }}
              />
            </>
          )}
        </>
      )
    },
    {
      title: 'Lần cuối cập nhật',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: text => (text ? moment(text).format('HH:mm DD/MM/YYYY') : 'N/A')
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'expiration_date',
      key: 'expiration_date',
      render: text => (text ? moment(text).format('DD/MM/YYYY') : 'N/A')
    },
    {
      title: 'Hành động',
      key: 'action',
      render: (text, record) => (
        <>
          {/* Nút Xóa với icon */}
          <Popconfirm
            title="Bạn có chắc chắn muốn xóa domain này không?"
            onConfirm={() => handleDelete(record.id)}
            okText="Xoá"
            cancelText="Không"
          >
            <Button type="link" danger icon={<DeleteOutlined />} />
          </Popconfirm>

          {/* Nút Gia hạn với icon */}
          <Button
            type="link"
            icon={<SyncOutlined />}
            onClick={() => handleSync(record.id, record.userId)} // Truyền id domain và user
          />
        </>
      )
    }
  ]

  return (
    <div>
      <Row justify="end" style={{ marginBottom: 16, marginTop: 16 }}>
        <Col>
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            Tạo mới
          </Button>
        </Col>
      </Row>

      <Row justify="center" style={{ marginBottom: 16 }}>
        <Col span={8}>
          <Input
            placeholder="Tìm kiếm theo subdomain"
            value={searchText}
            onChange={handleSearch}
            style={{ width: '100%' }}
          />
        </Col>
      </Row>

      {loading ? (
        <Spin size="large" />
      ) : (
        <Table dataSource={filteredDomains} columns={columns} rowKey="id" />
      )}

      {/* Modal tạo mới */}
      <CreateDomain
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onDomainCreated={handleDomainCreated}
      />
    </div>
  )
}

export default DomainList
