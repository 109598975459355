import React from 'react'
import AppHeader from '../components/App/Header'
import AppFooter from '../components/App/Footer'
import { Layout } from 'antd'
import { Outlet, useLocation } from 'react-router-dom'

const LayoutApp = () => {
  const location = useLocation()

  // Kiểm tra nếu đường dẫn hiện tại là '/signup' hoặc '/signin'
  const isAuthPage =
    location.pathname === '/signup' ||
    location.pathname === '/signin' ||
    location.pathname === '/reset-password'

  return (
    <Layout>
      {/* Chỉ render header nếu không phải ở trang đăng ký hoặc đăng nhập */}
      {!isAuthPage && <AppHeader />}

      <Outlet />

      <AppFooter />
    </Layout>
  )
}

export default LayoutApp
