import { useState, useEffect } from 'react'
import { Spin, Card, Button, Modal } from 'antd'
import { getUserWithPlanAndDomains } from '../../api/userApi' // API để lấy thông tin user với populate
import moment from 'moment' // Sử dụng moment để định dạng ngày
import ChangePlan from './ChangePlan' // Import component ChangePlan

const Plan = () => {
  const [userData, setUserData] = useState(null) // Lưu thông tin user, plan và domains
  const [loading, setLoading] = useState(true) // Trạng thái loading
  const [isModalVisible, setIsModalVisible] = useState(false) // Trạng thái của modal

  // Hàm tải thông tin user với các trường plan và domains
  const fetchUserData = async () => {
    try {
      const data = await getUserWithPlanAndDomains() // Gọi API để lấy thông tin user với plan và domains
      setUserData(data)
    } catch (error) {
      console.error('Failed to fetch user data:', error)
    } finally {
      setLoading(false)
    }
  }

  // Sử dụng useEffect để tải dữ liệu khi component được render
  useEffect(() => {
    fetchUserData()
  }, [])

  const showModal = () => {
    setIsModalVisible(true) // Hiển thị modal khi bấm nút "Nâng cấp gói"
  }

  const handleCancel = () => {
    setIsModalVisible(false) // Đóng modal khi bấm nút Cancel
  }
  // Trong component Plan.jsx
  const handlePlanChange = newPlan => {
    // Xử lý khi người dùng chọn một gói mới, ví dụ như gọi API để cập nhật plan của user
    console.log('Selected plan:', newPlan)
  }

  if (loading) {
    return <Spin size="large" />
  }

  if (!userData) {
    return <p>Failed to load user data.</p>
  }

  // Lấy thông tin plan và domains từ userData
  const { plan, domains } = userData
  const domainsUsed = domains.length // Số domain đã sử dụng
  const expirationDate = plan?.expiration_date // Lấy ngày hết hạn từ plan

  return (
    <Card title="Thông tin gói PLAN hiện tại">
      <p>
        <strong>Gói hiện tại:</strong> {plan.plan.name}
      </p>
      <p>
        <strong>Số domain:</strong> {domainsUsed}/{plan.amount}
      </p>
      <p>
        <strong>Ngày hết hạn:</strong>{' '}
        {expirationDate ? moment(expirationDate).format('DD/MM/YYYY') : 'N/A'}
      </p>

      <Button type="primary" onClick={showModal}>
        Nâng cấp gói
      </Button>

      {/* Modal nâng cấp gói */}
      <Modal
        title="Nâng cấp gói"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null} // Không có footer vì sẽ quản lý bằng component ChangePlan
      >
        <ChangePlan onPlanChange={handlePlanChange} />
      </Modal>
    </Card>
  )
}

export default Plan
