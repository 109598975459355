import { Routes, Route } from 'react-router-dom'
import LayoutApp from '../layouts/DefaultLayout'
import Home from '../pages/App/Home'
import SignIn from '../pages/SignIn/SignIn'
import SignUp from '../pages/SignUp/SignUp'
import Test from '../pages/App/Test'
import Profile from '../components/Profile/Profile'
import PayMoney from '../pages/PayMoney'
import ChangePasswordPage from '../pages/ChangePassword' // Import trang đổi mật khẩu
import ContactPage from '../pages/Contact'
import ResetPassword from '../pages/ResetPassword'

const RouterApp = () => {
  return (
    <Routes>
      <Route path="/" element={<LayoutApp />}>
        <Route index element={<Home />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/test" element={<Test />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/paymoney" element={<PayMoney />} />
        <Route path="/change-password" element={<ChangePasswordPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Route>
    </Routes>
  )
}

export default RouterApp
