import React from 'react'
import AppRoutes from './routes/DefaultRoute'
const App = () => {
  return (
    <>
      <AppRoutes />
    </>
  )
}
export default App
