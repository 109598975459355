import { getToken } from '../helpers'
import { API } from '../constant'

export const getAllPlans = async () => {
  try {
    const token = getToken()
    const response = await fetch(`${API}/plans`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching plans:', error)
    throw error
  }
}
