import { getToken } from '../helpers'
import { API } from '../constant'

export const createInvoice = async invoiceData => {
  try {
    const token = getToken()
    const response = await fetch(`${API}/invoices`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(invoiceData)
    })

    if (!response.ok) {
      throw new Error('Failed to create invoice')
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error creating invoice:', error)
    throw error
  }
}

export const getInvoiceByCode = async code => {
  try {
    const token = getToken()
    const response = await fetch(
      `${API}/invoices?filters[code][$eq]=${code}&populate=plan,users_permissions_user`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    if (!response.ok) {
      throw new Error('Failed to fetch invoice')
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching invoice:', error)
    throw error
  }
}
