import { useState, useEffect } from 'react'
import { Modal, Form, Input, Button, message } from 'antd'
import { createDomain, checkSubdomain } from '../../api/domainApi'
import { useAuthContext } from '../../context/AuthContext'

const CreateDomain = ({ visible, onClose, onDomainCreated }) => {
  const { user } = useAuthContext()
  const [subdomain, setSubdomain] = useState('')
  const [ip, setIp] = useState('127.0.0.1') // Giá trị mặc định cho IP là 127.0.0.1
  const [loading, setLoading] = useState(false)
  const [subdomainStatus, setSubdomainStatus] = useState('') // Để lưu trạng thái của subdomain (error hoặc available)
  const [isCheckingSubdomain, setIsCheckingSubdomain] = useState(false) // Trạng thái kiểm tra subdomain

  // Hàm kiểm tra subdomain
  const validateSubdomain = async value => {
    if (!value || value.length <= 4) {
      setSubdomainStatus('short') // Subdomain quá ngắn
      return
    }

    setIsCheckingSubdomain(true)
    try {
      const isAvailable = await checkSubdomain(value)
      if (!isAvailable) {
        setSubdomainStatus('error') // Đặt trạng thái là error nếu subdomain đã tồn tại
      } else {
        setSubdomainStatus('available') // Đặt trạng thái là available nếu subdomain hợp lệ
      }
    } catch (error) {
      setSubdomainStatus('error')
    } finally {
      setIsCheckingSubdomain(false)
    }
  }

  const handleCreate = async () => {
    if (!subdomain) {
      message.error('Subdomain is required')
      return
    }

    if (subdomainStatus === 'error') {
      message.error('Please choose a valid subdomain.')
      return
    }

    setLoading(true)

    try {
      const hostname = `${subdomain}.cretadns.com` // Tạo giá trị hostname

      const newDomain = {
        data: {
          subdomain,
          hostname, // Thêm trường hostname
          ip: ip || '127.0.0.1', // Nếu IP không được nhập, sử dụng giá trị mặc định
          users_permissions_user: user.id // Sử dụng ID của người dùng từ AuthContext
        }
      }

      // Gọi API để tạo domain
      const response = await createDomain(newDomain)
      message.success('Domain created successfully')
      onDomainCreated(response) // Gọi callback để cập nhật danh sách domain
      setSubdomain('') // Reset giá trị form
      setIp('127.0.0.1') // Reset IP về mặc định
      onClose() // Đóng modal
    } catch (error) {
      // Hiển thị thông báo lỗi chi tiết từ API
      message.error(
        error.message || 'Failed to create domain due to unknown error.'
      )
    } finally {
      setLoading(false)
    }
  }

  // Sử dụng useEffect để kiểm tra subdomain khi người dùng nhập
  useEffect(() => {
    if (subdomain) {
      const timer = setTimeout(() => {
        validateSubdomain(subdomain)
      }, 500) // Đợi 500ms trước khi kiểm tra để tránh gửi yêu cầu liên tục
      return () => clearTimeout(timer) // Xóa timeout nếu subdomain thay đổi
    }
  }, [subdomain])

  return (
    <Modal
      title="Tạo mới domain"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <Form layout="vertical">
        <Form.Item
          label="Subdomain"
          validateStatus={
            subdomainStatus === 'error' || subdomainStatus === 'short'
              ? 'error'
              : subdomainStatus === 'available'
              ? 'success'
              : ''
          }
          help={
            subdomainStatus === 'error' ? (
              'Subdomain đã tồn tại'
            ) : subdomainStatus === 'short' ? (
              'Subdomain cần nhiều hơn 5 ký tự'
            ) : subdomainStatus === 'available' ? (
              <span style={{ color: 'green' }}>
                Subdomain khả dụng - có thể dùng
              </span>
            ) : (
              ''
            )
          }
        >
          <Input
            value={subdomain}
            onChange={e => setSubdomain(e.target.value)}
            placeholder="Nhập subdomain"
          />
        </Form.Item>
        <Form.Item label="IP Address">
          <Input
            value={ip}
            onChange={e => setIp(e.target.value)}
            placeholder="Nhập địa chỉ IP (mặc định là 127.0.0.1)"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleCreate} loading={loading} block>
            Tạo mới
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateDomain
