import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal, // Import thêm Modal
  Row,
  Spin,
  Typography
} from 'antd'
import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'
import useScreenSize from '../../hooks/useScreenSize'
import { API } from '../../constant'
import { setToken } from '../../helpers'

const SignIn = () => {
  const { isDesktopView } = useScreenSize()
  const navigate = useNavigate()

  const { setUser } = useAuthContext()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [isForgotPasswordVisible, setIsForgotPasswordVisible] = useState(false) // State cho modal quên mật khẩu
  const [isSendingResetEmail, setIsSendingResetEmail] = useState(false) // State cho nút gửi email
  const [resetEmail, setResetEmail] = useState('') // State lưu email quên mật khẩu

  const onFinish = async values => {
    setIsLoading(true)
    try {
      const value = {
        identifier: values.email,
        password: values.password
      }
      const response = await fetch(`${API}/auth/local`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(value)
      })

      const data = await response.json()
      if (data?.error) {
        throw data?.error
      } else {
        // set the token
        setToken(data.jwt)

        // set the user
        setUser(data.user)

        message.success(`Welcome back ${data.user.username}!`)

        navigate('/', { replace: true })
      }
    } catch (error) {
      console.error(error)
      setError(error?.message ?? 'Something went wrong!')
    } finally {
      setIsLoading(false)
    }
  }

  const handleForgotPassword = async () => {
    setIsSendingResetEmail(true)
    try {
      const response = await fetch(`${API}/auth/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: resetEmail })
      })
      const data = await response.json()
      if (data?.error) {
        throw data?.error
      } else {
        message.success('Kiểm tra email của bạn để đặt lại mật khẩu.')
        setIsForgotPasswordVisible(false)
      }
    } catch (error) {
      console.error(error)
      message.error(error?.message ?? 'Đã có lỗi xảy ra!')
    } finally {
      setIsSendingResetEmail(false)
    }
  }

  return (
    <Fragment>
      <Row align="middle">
        <Col span={isDesktopView ? 8 : 24} offset={isDesktopView ? 8 : 0}>
          <Card title="">
            {error ? (
              <Alert
                className="alert_error"
                message={error}
                type="error"
                closable
                afterClose={() => setError('')}
              />
            ) : null}
            <Form
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email'
                  }
                ]}
              >
                <Input placeholder="Email address" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login_submit_btn"
                >
                  Đăng nhập {isLoading && <Spin size="small" />}
                </Button>
              </Form.Item>
            </Form>
            <Typography.Paragraph className="form_help_text">
              <Link to="#" onClick={() => setIsForgotPasswordVisible(true)}>
                Quên mật khẩu?
              </Link>
            </Typography.Paragraph>
            <Typography.Paragraph className="form_help_text">
              Lần đầu tới đây? <Link to="/signup">Đăng ký tài khoản</Link>
            </Typography.Paragraph>
          </Card>
        </Col>
      </Row>

      {/* Modal Quên Mật Khẩu */}
      <Modal
        title="Quên mật khẩu"
        visible={isForgotPasswordVisible}
        onCancel={() => setIsForgotPasswordVisible(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => setIsForgotPasswordVisible(false)}
          >
            Hủy
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleForgotPassword}
            loading={isSendingResetEmail}
          >
            Gửi email đặt lại mật khẩu
          </Button>
        ]}
      >
        <Form layout="vertical">
          <Form.Item
            label="Email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Vui lòng nhập email hợp lệ!'
              }
            ]}
          >
            <Input
              placeholder="Nhập email của bạn"
              value={resetEmail}
              onChange={e => setResetEmail(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  )
}

export default SignIn
