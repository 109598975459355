import { getToken } from '../helpers'
import { API } from '../constant'

export const getDomainById = async domainId => {
  try {
    const token = getToken()
    const response = await fetch(`${API}/domains/${domainId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching domain details:', error)
    throw error
  }
}

export const updateDomain = async (domainId, updatedData) => {
  try {
    const token = getToken()
    const response = await fetch(`${API}/domains/${domainId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(updatedData)
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error updating domain details:', error)
    throw error
  }
}

export const deleteDomain = async domainId => {
  try {
    const token = getToken()
    const response = await fetch(`${API}/domains/${domainId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error deleting domain:', error)
    throw error
  }
}

export const createDomain = async domainData => {
  try {
    const token = getToken()

    if (!token) {
      throw new Error('Unauthorized: Token is missing.')
    }

    const response = await fetch(`${API}/domains`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}` // Gửi token để xác thực
      },
      body: JSON.stringify(domainData)
    })

    const data = await response.json()

    // Kiểm tra xem API có trả về lỗi hay không
    if (!response.ok) {
      // Ném ra lỗi nếu status code không phải 2xx
      console.log('Error creating domain:', data)
      const errorMessage = data.error.message || 'Failed to create domain.'
      throw new Error(errorMessage)
    }

    return data // Trả về dữ liệu nếu thành công
  } catch (error) {
    // console.error('Error creating domain:', error)
    throw error // Ném lại lỗi để được xử lý trong component
  }
}

export const getDomains = async () => {
  // Function to fetch all domains
  try {
    const token = getToken()
    const response = await fetch(`${API}/domains`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    const data = await response.json()
    return data.data.attributes.results
  } catch (error) {
    console.error('Error fetching domains:', error)
    throw error
  }
}
// In the code snippet above, the domainApi.js file contains functions to interact with the domain-related endpoints of the API. These functions include fetching domain details by ID, updating domain details, deleting a domain, and creating a new domain.

export const syncDomain = async (domainId, userId) => {
  try {
    const token = getToken()
    const response = await fetch(`${API}/domain/sync`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        domainId, // Gửi domainId
        userId // Gửi userId
      })
    })
    const data = await response.json()

    if (!response.ok) {
      throw new Error(data.message || 'Error syncing domain')
    }

    return data
  } catch (error) {
    console.error('Error syncing domain:', error)
    throw error
  }
}

export const updateDomainIP = async (domainId, newIP) => {
  try {
    const token = getToken()

    if (!token) {
      throw new Error('Unauthorized: Token is missing.')
    }

    const response = await fetch(`${API}/domains/${domainId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        data: {
          ip: newIP // Chỉ cập nhật trường IP
        }
      })
    })

    const data = await response.json()

    // Kiểm tra xem API có trả về lỗi hay không
    if (!response.ok) {
      console.log('Error updating domain IP:', data)
      const errorMessage = data.error.message || 'Failed to update domain IP.'
      throw new Error(errorMessage)
    }

    return data // Trả về dữ liệu nếu thành công
  } catch (error) {
    console.error('Error updating domain IP:', error)
    throw error
  }
}

export const checkSubdomain = async subdomain => {
  try {
    const response = await fetch(
      `${API}/domain/check-subdomain?subdomain=${subdomain}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}` // Gửi token để xác thực nếu cần
        }
      }
    )

    const data = await response.json()

    // Kiểm tra phản hồi từ API
    if (!response.ok) {
      throw new Error(data.message || 'Failed to check subdomain.')
    }

    return data.isAvailable // API trả về giá trị true nếu subdomain chưa tồn tại
  } catch (error) {
    throw error
  }
}
