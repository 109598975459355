import React from 'react'
import { Card } from 'antd'
import Plan from './Plan' // Import Plan component

const Profile = () => {
  return (
    <Card className="profile_page_card">
      {/* Hiển thị Plan ở đây */}
      <Plan />
    </Card>
  )
}

export default Profile
